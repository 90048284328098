import { isCategoryEligibleForDownPayment } from "~/helpers/ecommerce/hardwareOrderHelper";

export function useIsEligibleForDownPayment(product?: any) {
	const { eCommerceProduct } = useECommerceProduct(product);
	const { isWifiProduct } = useIsWifiProduct(product);

	const isEligibleForDownPayment = computed<boolean>(
		() => !isWifiProduct.value && isCategoryEligibleForDownPayment(eCommerceProduct.value?.category),
	);

	return { isEligibleForDownPayment };
}
